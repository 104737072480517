@use "sass:color";

$base-font-family: -apple-system-font, HelveticaNeue, 'Helvetica Neue', Helvetica, sans-serif !default;
$base-font-size:   14px !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #484848 !default;
$background-color: #ffffff !default;
$brand-color:      #007aff !default;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}


@mixin vertical-rhythm {
    margin-bottom: calc($spacing-unit / 2);
}
/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure {
    @include vertical-rhythm;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings (edited by Kristian)
 */
h1, h2, h3, h4, h5, h6 {
	font-weight: normal; color: #181818;
}
h2, h3, h4, h5 {
	padding-top: $spacing-unit;
}
h3 {
	padding-top: $spacing-unit * 2; font-size: 1.4em;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: color.adjust($brand-color, $lightness: -15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: calc($spacing-unit / 2);
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}
